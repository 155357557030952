























































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Address, {AddressType, AddressSelect, AddressParentTypes} from "@/models/Address";
import AddressService from "@/services/AddressService";
import { getModule } from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Client from "@/models/Client";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import PotentialClient from "@/models/PotentialClient";
import AddressDialog from "@/components/dialog/AddressDialog.vue";
import CreateAddressDialog from "@/components/dialog/CreateAddressDialog.vue";

@Component( {
  computed: {
    AddressParentTypes() {
      return AddressParentTypes
    }
  },
  components: { AddressDialog, CreateAddressDialog } } )
export default class PotentialClientAddressTab extends Vue {

	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement;
	@Prop() readonly potentialClient!: PotentialClient;
	dialog: boolean = false;
	addressDialog: boolean = false;
	loading: boolean = false;
	//@ts-ignore
	addressType: AddressType = AddressType;
	address: Address = new Address();
	addresses: Address[] = [];
	headers = [
		{ text: this.lang.name, value: "name", width: "auto", align: "center" },
		{ text: this.lang.name, value: "email", width: "auto", align: "center" },
		{ text: this.lang.address, value: "address", width: "auto", align: "center" },
		{ text: this.lang.postalCode, value: "postalCode", width: "auto", align: "center" },
		{ text: this.lang.actions, value: "actions", width: "auto", align: "center" },
	]
	minLength: number = 4
	maxLength: number = 250

	rules = {
		required: (v: string) => (!!v) || this.lang.emptyLabel,
		min4Characters: (v: string) => (!!v) && (v.length + 1 > 4) || this.lang.fieldMin4Characters,
		minLength: (v: string) => (!!v) && (v.length >= this.minLength) || `El campo debe ser de minimo ${this.minLength} caracteres`,
		postalCode:	(v: string) => (!!v) && StringTool.validatePostalCode(v) || this.lang.invalidPostalCode,
		email: (v: string) => (!!v) && StringTool.validateEmail(v) || this.lang.emailNoValid

	}

	created() {
		this.refresh();
	}

	refresh() {
		AddressService.getAddressesByPotentialClient(this, this.addresses, this.potentialClient.id!)
	}

	addressDetail(address: Address) {
		this.addressDialog = true;
		this.address = address;
	}

	closeDetail() {
		this.addressDialog = false;
		this.address = new Address();
	}

	deleteAddress(item: Address) {
		getModule(DialogModule).showDialog(
			 new Dialog(this.lang.warning, this.lang.deleteAddressConfirmation, () =>
					AddressService.deleteAddress(this, item.id!)
			 )
		)
	}

	createAddress() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(
				 new Dialog(this.lang.warning, this.lang.createAddressConfirmation, () =>
						AddressService.postAddressToPotentialClient(this, this.address, this.potentialClient.id!)
				 )
			)
		}
	}
}
