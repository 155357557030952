var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"mb-0 pb-0"},[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.addresses))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(_vm._s(_vm.lang.createAddress))])],1)],1),_c('v-card-text',{staticClass:"my-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.addresses,"items-per-page":5,"loading":_vm.loading,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.email",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.postalCode",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.address",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteAddress(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.addressDetail(item)}}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CreateAddressDialog',{attrs:{"clientId":_vm.client.id,"address-type":_vm.AddressParentTypes.CLIENT},on:{"close":function($event){_vm.dialog = false},"refresh":_vm.refresh}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.addressDialog),callback:function ($$v) {_vm.addressDialog=$$v},expression:"addressDialog"}},[_c('AddressDialog',{attrs:{"address-id":_vm.address.id},on:{"close":function($event){_vm.addressDialog = false},"refresh":_vm.refresh}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }