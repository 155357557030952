







































































































import {Component, Vue} from "vue-property-decorator";
import AddressTab from "@/components/tabs/AddressTab.vue";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ClientRegisterTab from "@/components/tabs/ClientRegisterTab.vue";
import LangModule from "@/store/LangModule";
import PotentialClientService from "@/services/PotentialClientService";
import PotentialClient from "@/models/PotentialClient";
import PotentialClientTab from "@/components/tabs/PotentialClientTab.vue";
import PotentialClientAddressTab from "@/components/tabs/PotentialClientAddressTab.vue";

@Component({components: {AddressTab, ClientRegisterTab, PotentialClientTab, PotentialClientAddressTab}})
export default class PotentialClientView extends Vue {

	lang: any = getModule(LangModule).lang
	loading: boolean = false
	potentialClient: PotentialClient = new PotentialClient()
	tab: number = 0

	deleteClient() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.deletePotentialClientConfirmation,
			 () => PotentialClientService.deletePotentialClient(this, this.potentialClient.id!)
		))
	}

	deactivateClient() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.deactivatePotentialClientConfirmation,
			 () => PotentialClientService.activatePotentialClient(this, this.potentialClient.id!, false)
		))
	}

	activateClient() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.activateClientConfirmation,
			 () => PotentialClientService.activatePotentialClient(this, this.potentialClient.id!, true)
		))
	}

	created() {
		this.refresh()
	}

	refresh() {
		PotentialClientService.getPotentialClient(this, Number(this.$route.params.id))
	}

}
