

























import {Component, Prop, Vue} from "vue-property-decorator";
import ClientRegister from "@/models/ClientRegister";
import Client from "@/models/Client";
import ClientRegisterService from "@/services/ClientRegisterService";

@Component
export default class ClientRegisterTab extends Vue {
    @Prop() readonly client!: Client
    loading: boolean = false
    clientRegisters: ClientRegister[] = []
    headers = [
        { text: "Id", value: "id", width: "200px" },
        { text: "Usado por:", value: "usedBy.email", width: "200px" },
        { text: "Fecha", value: "createdAt", width: "200px" },
    ]


    created() {
        ClientRegisterService.getClientRegister(this, this.clientRegisters, this.client.id!)
    }
}
